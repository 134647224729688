jQuery(document).ready(function($) {

    if( ! window['console']) window.console = {log:function(){}};

    //----------------------------------------------------------------------------
    // IS MOBILE LIVE ONLY
    //----------------------------------------------------------------------------
    if(isMobile.apple.device){
        $("data-player-rc-iframe-live").attr("src","http://hdflash_1-lh.akamaihd.net/i/cancbft_1@95875/master.m3u8");
    }

    //---------------------------------------------------------------------
    // IMG loading effect
    //---------------------------------------------------------------------
    window.initImgLazyLoad = function(){
        $('img[data-img]').each(function(index, el) {
            var img = $(el).attr('src');
            var container = $(this).parents('[data-bg-loaded-container]');

            $('<img/>').attr('src', img).load(function() {
                $(this).remove(); // prevent memory leaks
                container.addClass('bg-loaded');
            });
        });
    };
    initImgLazyLoad();

    //---------------------------------------------------------------------
    // scroll to top (button go up)
    //---------------------------------------------------------------------

        $(window).scroll(function(e){
            //$('.scrollup').css("bottom", (-$(window).scrollTop() + 25)+"px");

           if ($(this).scrollTop() > 0) {
               $('[data-scrollup]').fadeIn();
           } else {
                $('[data-scrollup]').fadeOut();
            }
        });
        $('[data-scrollup]').click(function(){
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });

    //---------------------------------------------------------------------
    // open close button
    //---------------------------------------------------------------------

    $('[data-readmore]').click(function(e){
        e.preventDefault();
        $(this).parent().toggleClass("active");
        $(this).toggleClass("active");
        $(this).parent().find('[data-perfect-scroll]').toggle();

        setTimeout(function(){
            $('[data-perfect-scroll]').perfectScrollbar();
        }, 500);
    });

    //---------------------------------------------------------------------
    // dropdown menu (mobile), close it on click
    //---------------------------------------------------------------------

    $('.nav a').on('click', function(){
        if(isMobile.phone == false) return;
        $('.navbar-toggle').click() //bootstrap 3.x by Richard
    });

    //---------------------------------------------------------------------
    // Gallery photos (magnificPopup)
    //---------------------------------------------------------------------

    $('[data-gallery]').each(function(index , value){
      var gallery = $(this);
      var galleryImages = $(this).data('links').split(',');
        var items = [];
        for(var i=0;i<galleryImages.length; i++){
          items.push({
            src:galleryImages[i],
            title:''
          });
        }
        gallery.magnificPopup({
          mainClass: 'mfp-with-fade',
          items:items,
          gallery:{
            enabled:true,
            tPrev: $(this).data('prev-text'),
            tNext: $(this).data('next-text')
          },
          type: 'image'
        });
    });
});

//---------------------------------------------------------------------
// jQuery to collapse the navbar on scroll
//---------------------------------------------------------------------
$(window).scroll(function() {
    if ($(".navbar").offset().top > 50) {
        $(".navbar-fixed-top").addClass("top-nav-collapse");
    } else {
        $(".navbar-fixed-top").removeClass("top-nav-collapse");
    }
});

//---------------------------------------------------------------------
// jQuery for page scrolling feature - requires jQuery Easing plugin
//---------------------------------------------------------------------
$(function() {
    $('[data-scrollnav]').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 90 + 'px'
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});