//----------------------------------------------------------------------------
// Dispatch events
//----------------------------------------------------------------------------
function dispatchSRCEvents(idMedianet){
    if(typeof srcPlayer == 'function'){
        srcPlayer('addEventListener', { eventName: 'begin', callback: beginCallback(idMedianet) });
        srcPlayer('addEventListener', { eventName: 'end', callback: endCallback(idMedianet) });

        srcPlayer('addEventListener', { eventName: 'start', callback: startCallback(idMedianet) });
        srcPlayer('addEventListener', { eventName: 'play', callback: playCallback(idMedianet) });
        srcPlayer('addEventListener', { eventName: 'pause', callback: pauseCallback(idMedianet) });

        srcPlayer('addEventListener', { eventName: 'extract', callback: extractCallback(idMedianet) });
        srcPlayer('addEventListener', { eventName: 'seek', callback: seekCallback(idMedianet) });

        //srcPlayer('addEventListener', { eventName: 'currentTime', callback: currentTimeCallback(idMedianet) });
        //srcPlayer('addEventListener', { eventName: 'loopPlaying', callback: loopPlayingCallback(idMedianet) });

        //srcPlayer('addEventListener', { eventName: 'pubStarted', callback: pubStartedCallback(idMedianet) });
        srcPlayer('addEventListener', { eventName: 'pubCompleted', callback: pubCompletedCallback(idMedianet) });

        //srcPlayer('addEventListener', { eventName: 'next', callback: nextCallback(idMedianet) });
        //srcPlayer('addEventListener', { eventName: 'share', callback: shareCallback(idMedianet) });
        //srcPlayer('addEventListener', { eventName: 'changeVolume', callback: changeVolumeCallback(idMedianet) });

        //srcPlayer('addEventListener', { eventName: 'error', callback: myCallBack_error });
    }
}


//----------------------------------------------------------------------------
// Callback fonctions
//----------------------------------------------------------------------------

// Survient 1 fois au debut de l’extrait avant la pub  preroll
function beginCallback(videoId){
    console.log('Google Analytics, video RC : beginCallback()');
}

// Survient 1 fois à la fin de l’extrait après la pub postroll
function endCallback(videoId){
    console.log('Google Analytics, video RC : endCallback()');
    ga('send', 'event',  'Video', videoId, 'Fin de la vidéo');
}


/**************************************************************/
// Survient lorque l'extrait commence à jouer. Event est envoyé une fois pour chaque extrait
function startCallback(videoId){
    console.log('Google Analytics, video RC : startCallback()');
    ga('send', 'event', 'Video', videoId, 'Début de l\'extrait vidéo');
}

// Survient lorque l'extrait commence à jouer.
function playCallback(videoId){
    console.log('Google Analytics, video RC : playCallback()');
    ga('send', 'event', 'Video', videoId, 'Lecture de la vidéo');
}

// Survient lorque le média est mis en pause.
function pauseCallback(videoId){
    console.log('Google Analytics, video RC : pauseCallback()');
    ga('send', 'event', 'Video', videoId, 'Pause');
}


/**************************************************************/
// Survient lorque l’utilisateur click sur le bouton d’extraction et que le player se met en mode popup.
function extractCallback(videoId){
    console.log('Google Analytics, video RC : extractCallback()');
}

// Survient lorque l’usager seek manuellement dans le contenu. Le temps courant est passé en paramètre de la fonction de callback
function seekCallback(videoId){
    console.log('Google Analytics, video RC : seekCallback()');
}


/**************************************************************/
// Survient tous les 100 milisecondes. Cette fonction sert dans le cas d’une console voulant afficher son propre contôleur.
// Le temps courant est passé en paramètre de la fonction de callback
function currentTimeCallback(temps, videoId){
    console.log('Google Analytics, video RC : currentTimeCallback()');
}

// Survient à tous les 10 secondes durant l’écoute. Utile pour les notifications dans facebook, comme dans tou.tv.
// Aucun paramètre n’est passé au callback.
function loopPlayingCallback(videoId){
    console.log('Google Analytics, video RC : loopPlayingCallback()');
    ga('send', 'event', 'Video', videoId, 'Pub visionnée');
}


/**************************************************************/
// Survient au début de chacune des pubs
function pubStartedCallback(videoId){
    console.log('Google Analytics, video RC : pubStartedCallback()');
}

// Survient à la fin de chacune des pubs
function pubCompletedCallback(videoId){
    console.log('Google Analytics, video RC : pubCompletedCallback()');
    ga('send', 'event', 'Video', videoId, 'Pub visionnée');
}


/**************************************************************/
// Survient au début de chacune des pubs
function nextCallback(videoId){
    console.log('Google Analytics, video RC : nextCallback()');
}

// Survient à la fin de chacune des pubs
function shareCallback(videoId){
    console.log('Google Analytics, video RC : shareCallback()');
    ga('send', 'event', 'Video', videoId, 'Pub visionnée');
}

// Survient à la fin de chacune des pubs
function changeVolumeCallback(videoId){
    console.log('Google Analytics, video RC : changeVolumeCallback()');
    ga('send', 'event', 'Video', videoId, 'Pub visionnée');
}


/**************************************************************/
// Envoie un message si un error c’est produit dans le player
function myCallBack_error(message){
    console.log('Google Analytics, video RC : Event "error" was fired -> myCallBack_error().');
    console.log(message);
}